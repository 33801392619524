import './HowItWorksContent.css';

function HowItWorksContent({imgSvg, header, caption}) {
  return (
    <>
        <div className='how-it-works-content'>
            {/* <img src={imgSrc} alt={header} className='how-it-works-content-img' /> */}
            {imgSvg}
            <div className='how-it-works-content-header'>{header}</div>
            <div className='how-it-works-content-caption'>{caption}</div>
        </div>
    </>
  );
}

export default HowItWorksContent;
