import "./CTAWrapper.css";
import { ReactComponent as MobileQuoteLogoSvg }  from "../assets/svg/mobile-quote.svg";
import { ReactComponent as DesktopQuoteLogoSvg }  from "../assets/svg/desktop-quote.svg";

const handleClick = () => {
    window.location.href = 'https://linktr.ee/sforsubtle';
};

function CTAWrapper() {
    return (
        <>
            <div className="cta-wrapper">
                <div className="cta-frame">
                    <div className="quote-wrapper">
                        <div className="quote-header">
                        Get a free quote from us today!
                        </div>
                        <div className="quote-description">
                        Schedule a call with us to get a free quote now for your product experience project now
                        </div>
                        <button className="quote-button" onClick={handleClick}>
                        Get free quote
                        </button>
                    </div>
                    <DesktopQuoteLogoSvg className="desktop-quote-svg"/>
                    <MobileQuoteLogoSvg className="mobile-quote-svg"/>
                </div>
            </div>
        </>
    );
}

export default CTAWrapper;