import React, { useState } from 'react';
import './NavBtn.css';
import { AdjustColorBrightness, GetBrightness } from '../hook/AdjustColorBrightness';

// import { scroller, animateScroll as scroll } from 'react-scroll';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

function NavBtn({ Name, ID, TextColor, onClick }) {
    const [hoverColor, setHoverColor] = useState(null);

    // const handleClick = () => {
    //     if (onClick) {
    //         onClick(); // Call the onClick function passed from the parent component
    //     }

    //     if (ID === 'about-subtle') {
    //         window.scrollTo({ top: 0, behavior: 'smooth' });
    //     } 
        
    //     else if (ID === 'services') 
    //     {
    //         const viewportWidth = window.innerWidth;
    //         const offset = viewportWidth < 1050 ? 100 : 105;
    //         const element = document.getElementById(ID);
    //         if (element) 
    //         {
    //             const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    //             window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
    //         }
    //     } 
        
    //     else if (ID === 'work') 
    //         {
    //             const viewportWidth = window.innerWidth;
    //             const offset = viewportWidth < 1050 ? 100 : 20;
    //             const element = document.getElementById(ID);
    //             if (element) 
    //             {
    //                 const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
    //                 window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
    //             }
    //         } 
        
    //     else {
    //         const element = document.getElementById(ID);
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //         }
    //     }
    // };

    // const handleClick = () => {
    //     if (onClick) {
    //         onClick(); // Call the onClick function passed from the parent component
    //     }
    
    //     const scrollOptions = {
    //         duration: 350, // Duration of the scroll in ms
    //         delay: 0,      // Delay before the scroll starts
    //         smooth: 'easeOutCubic', // Scroll animation easing
    //     };
    
    //     if (ID === 'about-subtle') {
    //         scroll.scrollToTop(scrollOptions);
    //     } else if (ID === 'services') {
    //         const viewportWidth = window.innerWidth;
    //         const offset = viewportWidth < 1050 ? -100 : -105; // Negative to adjust the scroll position
    //         scroller.scrollTo(ID, { ...scrollOptions, offset });
    //     } else if (ID === 'work') {
    //         const viewportWidth = window.innerWidth;
    //         const offset = viewportWidth < 1050 ? -100 : -20; // Negative to adjust the scroll position
    //         scroller.scrollTo(ID, { ...scrollOptions, offset });
    //     } else {
    //         scroller.scrollTo(ID, { ...scrollOptions });
    //     }
    // };

    const handleClick = () => {
        if (onClick) {
            onClick(); // Call the onClick function passed from the parent component
        }

        const scrollOptions = {
            duration: 0.15, // Duration of the scroll in seconds
            ease: 'power3.in', // Ease-out effect
        };

        if (ID === 'about-subtle') {
            gsap.to(window, {
                scrollTo: { y: 0 },
                duration: scrollOptions.duration,
                ease: scrollOptions.ease,
            });
        } 
        else {
            const element = document.getElementById(ID);
            if (element) {
                const viewportWidth = window.innerWidth;
                const offset = ID === 'services'
                    ? (viewportWidth < 1275 ? -100 : -105)
                    : ID === 'work'
                    ? (viewportWidth < 1275 ? -120 : -130)
                    : ID === 'pricing'
                    ? (viewportWidth < 1275 ? -360 : -355)
                    : (viewportWidth < 1275 ? -100 : -20); // Default offset
            
                gsap.to(window, {
                    scrollTo: { y: element.offsetTop + offset },
                    duration: scrollOptions.duration,
                    ease: scrollOptions.ease,
                });
            }
        }
    };

    const handleMouseEnter = (e) => {
        const currentColor = TextColor;
        const rgb = currentColor.match(/\w\w/g).map(x => parseInt(x, 16));
        const hexColor = `#${rgb
            .map((x) => x.toString(16).padStart(2, '0'))
            .join('')}`;

        const brightness = GetBrightness(hexColor);
        const newColor =
            brightness > 150
                ? AdjustColorBrightness(hexColor, -20)
                : AdjustColorBrightness(hexColor, 4000);
        
        setHoverColor(newColor);
    };

    const handleMouseLeave = () => {
        setHoverColor(null);
    };

    return (
        <button
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className='nav-btn'
            style={{ color: hoverColor || TextColor }}
        >
            {Name}
        </button>
    );
}

export default NavBtn;
