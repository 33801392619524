import React, { useEffect, useRef } from 'react';
import './CTAScrollingBanner.css';
import { ReactComponent as DesktopCtaScrollUiuxBannerSvg } from "../assets/svg/desktop-uiux-cta-scroll.svg";
import { ReactComponent as DesktopCtaScrollMarketingBannerSvg } from "../assets/svg/desktop-marketing-cta-scroll.svg";
import { ReactComponent as DesktopCtaScrollBannerWebDevSvg } from "../assets/svg/desktop-webdev-cta-scroll.svg";
import { ReactComponent as DesktopCtaScrollBannerGraphDesSvg } from "../assets/svg/desktop-graphicdesign-cta-scroll.svg";
import { ReactComponent as MobileCtaScrollUiuxBannerSvg } from "../assets/svg/mobile-uiux-cta-scroll.svg";
import { ReactComponent as MobileCtaScrollMarketingBannerSvg } from "../assets/svg/mobile-marketing-cta-scroll.svg";
import { ReactComponent as MobileCtaScrollBannerWebDevSvg } from "../assets/svg/mobile-webdev-cta-scroll.svg";
import { ReactComponent as MobileCtaScrollBannerGraphDesSvg } from "../assets/svg/mobile-graphicdesign-cta-scroll.svg";
import CTAScrollingBannerContent from '../components/CTAScrollingBannerContent';

function CTAScrollingBanner() {

    const desktopScrollRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;
        
            if (viewportWidth < 1275) { // Only apply if viewport width is 1275px or more (old was 1050px)
                return;
            }
        
            const scrollTop = window.scrollY;
            const minScrollTop = 0;
            const maxScrollTop = 2 * viewportHeight; // Adjust as needed
        
            const elementPadding = 12 * 2; // left and right padding in pixels
            const totalRefWidth = 0.5 * desktopScrollRef.current.offsetWidth + elementPadding;
        
            if (scrollTop < minScrollTop || scrollTop > maxScrollTop) {
                if (desktopScrollRef.current) {
                    if (scrollTop === minScrollTop) {
                        desktopScrollRef.current.style.transform = `translate3d(0px, 0, 0)`;
                    } else if (scrollTop >= maxScrollTop) {
                        desktopScrollRef.current.style.transform = `translate3d(-${totalRefWidth}px, 0, 0)`;
                    }
                }
                return; // Exit if outside the scroll range
            }
        
            const maxScroll = maxScrollTop - minScrollTop;
            const scrollPercent = (scrollTop - minScrollTop) / maxScroll;
        
            if (desktopScrollRef.current) {
                const translateX = scrollPercent * totalRefWidth; // Calculate translateX in pixels
                desktopScrollRef.current.style.transform = `translate3d(-${translateX}px, 0, 0)`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="cta-scrolling-banner-wrapper" id = "services">
                <div className="cta-scrolling-banner-wrapper-scroller"
                id="desktop-cta-services"
                ref={desktopScrollRef}>
                    <CTAScrollingBannerContent svg={<DesktopCtaScrollUiuxBannerSvg />} caption={"UI/UX"} />
                    <CTAScrollingBannerContent svg={<DesktopCtaScrollBannerWebDevSvg />} caption={"Website Development"} />
                    <CTAScrollingBannerContent svg={<DesktopCtaScrollBannerGraphDesSvg />} caption={"Graphic Design"} />
                    <CTAScrollingBannerContent svg={<DesktopCtaScrollMarketingBannerSvg />} caption={"Marketing"} />

                    <CTAScrollingBannerContent svg={<DesktopCtaScrollUiuxBannerSvg />} caption={"UI/UX"} />
                    <CTAScrollingBannerContent svg={<DesktopCtaScrollBannerWebDevSvg />} caption={"Website Development"} />
                    <CTAScrollingBannerContent svg={<DesktopCtaScrollBannerGraphDesSvg />} caption={"Graphic Design"} />
                    <CTAScrollingBannerContent svg={<DesktopCtaScrollMarketingBannerSvg />} caption={"Marketing"} />
                </div>

                <div className="cta-scrolling-banner-wrapper-scroller" id ="mobile-cta-services">
                    <CTAScrollingBannerContent svg={<MobileCtaScrollUiuxBannerSvg />} caption={"UI/UX"} />
                    <CTAScrollingBannerContent svg={<MobileCtaScrollBannerWebDevSvg />} caption={"Website Development"} />
                    <CTAScrollingBannerContent svg={<MobileCtaScrollBannerGraphDesSvg />} caption={"Graphic Design"} />
                    <CTAScrollingBannerContent svg={<MobileCtaScrollMarketingBannerSvg />} caption={"Marketing"} />
                </div>
            </div>
        </>
    );
}

export default CTAScrollingBanner;
