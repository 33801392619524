import './CTAScrollingBannerContent.css';

function CTAScrollingBannerContent({svg, caption}) {


  return (
    <div className="cta-scrolling-content-wrapper">
      {svg}
      <p>{caption}</p>
    </div>
  );
}

export default CTAScrollingBannerContent;
