import './PastWorkWrapper.css';
import PastWorkContent from '../components/PastWorkContent';
import CultCreativeImg from "../assets/images/cult-creative.png";
import WannaScanImg from "../assets/images/wanna-scan.png";
import TradeWizardImg from "../assets/images/trade-wizard.png";
import RichiamoImg from "../assets/images/richiamo.png";
import GuruLabImg from "../assets/images/guru-lab.png";
import OxWhiteImg from "../assets/images/ox-white.png";

function PastWorkWrapper() {
  return (
    <>
      <div className='past-work-wrapper' id='work'>
        <div className='past-work-header-wrapper'>
            <div className='past-work-header'>
            Subtle designs with big impact
            </div>
            <div className='past-work-caption'>
            For the past few years, we’ve been helping businesses to create some magic
            </div>
        </div>
        <div className='past-work-row'>
          <PastWorkContent Image={CultCreativeImg} Name={"Cult Creative"} Category={"Dashboard"} />
          <PastWorkContent Image={WannaScanImg} Name={"Wanna Scan"} Category={"Mobile App"} />
          <PastWorkContent Image={TradeWizardImg} Name={"Trade Wizard"} Category={"Website Design"} />
        </div>
        <div className='past-work-row'>
          <PastWorkContent Image={RichiamoImg} Name={"Richiamo Coffee"} Category={"Web Mobile App"} />
          <PastWorkContent Image={GuruLabImg} Name={"GuruLab"} Category={"eCommerce"} />
          <PastWorkContent Image={OxWhiteImg} Name={"OxWhite"} Category={"Platform Design"} />
        </div>
      </div>
    </>
  );
}

export default PastWorkWrapper;
