import './HeroWrapper.css';

function HeroWrapper() {
  return (
    <>
      <div className='cta-hero-wrapper' id='about-subtle'>
        <div className='cta-hero-header'>
          <h1>
            The only <span className='cta-hero-wrapper-highlight'>subtle</span> push for your business
          </h1>
          <p className='cta-hero-wrapper-caption'>
            Embark on the journey of making a significant impact on your business with us
          </p>
        </div>

        <a href={"https://linktr.ee/sforsubtle"} className='book-call-btn'>
            {"Book a 30-min call"}
        </a>
      </div>
    </>
  );
}

export default HeroWrapper;