import React, { useState } from 'react';
import './FaqContent.css';

function FaqContent({Question, Answer}) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <>
      <div className={`faq-content-wrapper ${isClicked ? 'active' : ''}`} onClick={handleClick}>
        <div className='faq-content-header'>
          <header>
            {Question}
          </header>
          <button>
            <span/>
            <span/>
          </button>
        </div>
        <p className={isClicked ? 'visible' : 'hidden'}>
          {Answer}
        </p>
      </div>
    </>
  );
}

export default FaqContent;
