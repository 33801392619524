import React, { useState } from 'react';
import NavBtn from "./NavBtn";
import ContactUsBtn from './ContactUsBtn';
import './MobileNavBar.css';
import { ReactComponent as MobileSubtleStudioLogoSvg }  from "../assets/svg/mobile-header-subtle-studio.svg";
import { ReactComponent as HamburgerSvg }  from "../assets/svg/hamburger.svg";

function MobileNavBar() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNavBtnClick = () => {
        setIsDropdownOpen(false);
    };

    return (
        <>
            <div className={`blur-bg ${isDropdownOpen ? 'visible' : ''}`} onClick={toggleDropdown}/>
            <div className="mobile-navbar-wrapper">
                <a href="https://wearesubtle.co" style={{ display: 'inline-block' }}>
                    <div style={{ cursor: 'pointer' }}>
                        <MobileSubtleStudioLogoSvg />
                    </div>
                </a>
                <div className='hamburger-svg' onClick={toggleDropdown}>
                    <HamburgerSvg />
                </div>
            </div>

            <div className={`mobile-dropdown-wrapper ${isDropdownOpen ? 'open' : ''}`}>
                <NavBtn Name="About Subtle" ID="about-subtle" TextColor="#FFFFFF" onClick={handleNavBtnClick} />
                <NavBtn Name="Services" ID="services" TextColor="#FFFFFF" onClick={handleNavBtnClick} />
                <NavBtn Name="Work" ID="work" TextColor="#FFFFFF" onClick={handleNavBtnClick} />
                <NavBtn Name="Pricing" ID="pricing" TextColor="#FFFFFF" onClick={handleNavBtnClick} />
                <NavBtn Name="FAQ" ID="faq" TextColor="#FFFFFF" onClick={handleNavBtnClick} />
                <ContactUsBtn href="https://linktr.ee/sforsubtle" onClick={handleNavBtnClick} />
            </div>
        </>
    );
}

export default MobileNavBar;
