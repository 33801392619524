import React from 'react';
import './Switch.css';

function Switch({ isOn, handleToggle, toggledColor, baseColor }) {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={{
          background: isOn ? toggledColor : baseColor,
        }}
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span className="react-switch-button" />
      </label>
    </>
  );
}

export default Switch;
