import DesktopNavBar from "../components/DesktopNavBar.jsx";
import MobileNavBar from "../components/MobileNavBar.jsx";
import "./NavBarWrapper.css";

function NavBarWrapper() {
    return (
        <div className="navbar-wrapper">
            <MobileNavBar />
            <DesktopNavBar />
        </div>
    );
}

export default NavBarWrapper;

