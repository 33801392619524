import './CTAScrollingBanner2.css';
import { ReactComponent as PartnersBannerSvg } from "../assets/svg/partners-banner.svg";

function CTAScrollingBanner2() {

    return (
        <>
            <div className='cta-scrolling-banner-2-wrapper'>
                <p>
                We have successfully helped to design for businesses including
                </p>
                <div className='partners-scrolling-wrapper'>
                    <div className='partners-scrolling-container'>
                        <PartnersBannerSvg/>
                        <PartnersBannerSvg/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CTAScrollingBanner2;
