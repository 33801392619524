import React, { useState, useEffect } from 'react';
import './PastWorkContent.css';

function PastWorkContent({ Image, Name, Category }) {
  const [isScaled, setIsScaled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleImageClick = () => {
    setIsScaled(!isScaled);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsScaled(!isScaled);
  };

  useEffect(() => {
    // Disable scrolling when scaled
    document.body.style.overflow = isScaled ? 'hidden' : 'auto';

    // Cleanup function to reset overflow when the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isScaled]);

  return (
    <>
      <div
        className={`past-work-content-wrapper ${isScaled ? 'scaled' : ''}`}
        onClick={handleImageClick}
      >
        <img src={Image} className='past-work-content-img' alt={Name} />
        <div className='past-work-content-details'>
          <div className='past-work-content-name'>
            {Name}
          </div>
          <div className='past-work-content-category'>
            {Category}
          </div>
        </div>
      </div>

      <div
        className={`past-work-content-wrapper-placeholder ${isScaled ? 'scaled' : ''}`}
      >
        <img src={Image} className='past-work-content-img' alt={Name} />
        <div className='past-work-content-details'>
          <div className='past-work-content-name'>
            {Name}
          </div>
          <div className='past-work-content-category'>
            {Category}
          </div>
        </div>
      </div>

      <div className={`blur-bg2 ${isDropdownOpen ? 'visible' : ''}`} onClick={toggleDropdown} />
    </>
  );
}

export default PastWorkContent;
