import './App.css';
import NavBarWrapper from './tabs/NavBarWrapper';
import FooterWrapper from './tabs/FooterWrapper';
import CTAWrapper from './tabs/CTAWrapper';
import HeroWrapper from './tabs/HeroWrapper';
import CTAScrollingBanner from './tabs/CTAScrollingBanner';
import CTAScrollingBanner2 from './tabs/CTAScrollingBanner2';
import HowItWorksWrapper from './tabs/HowItWorksWrapper';
import WhySubtleWrapper from './tabs/WhySubtleWrapper';
import PastWorkWrapper from './tabs/PastWorkWrapper';
import PricesWrapper from './tabs/PricesWrapper';
import FaqWrapper from './tabs/FaqWrapper';

function App() {
  return (
    <>
      <NavBarWrapper/>

      <HeroWrapper/>
      
      <CTAScrollingBanner/>

      <CTAScrollingBanner2/>

      <HowItWorksWrapper/>

      <WhySubtleWrapper/>

      <PastWorkWrapper/>

      <PricesWrapper/>

      <FaqWrapper/>
      
      <CTAWrapper/>
      
      <FooterWrapper/>
    </>
  );
}

export default App;
