import React, { useState } from 'react';
import Switch from '../components/Switch';
import './PricesWrapper.css'
import PricesContent from '../components/PricesContent';

function PricesWrapper() {
  const [isWebDev, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn(!isWebDev);
  };

  return (
    <>
        <div className='price-wrapper'>
            <div className='price-header-wrapper'>
                <div className='price-header'>
                Choose your pricing warrior
                </div>

                <div className='price-header-wrapper-lower'>
                    <div className='slots-available-wrapper'>
                         5 slots left!
                    </div>

                    <div className='design-webdev-wrapper'>
                        <div>
                            Design
                        </div>
                        <Switch
                            isOn={isWebDev}
                            handleToggle={handleToggle}
                            toggledColor={"#B8FF00"}
                            baseColor={"#B5B3B3"}
                        />
                        <div>
                            Web Development
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='price-content-list'>
                <PricesContent Tier={"Standard"} Mode={isWebDev ? "WebDev" : "Design"}/>
                <PricesContent Tier={"Elite"} Mode={isWebDev ? "WebDev" : "Design"}/>
            </div>
        </div>
    </>
  );
}

export default PricesWrapper;