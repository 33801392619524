import './HowItWorksWrapper.css';
import HowItWorksContent from '../components/HowItWorksContent';

import { ReactComponent as DesktopSubscribeSvg } from "../assets/svg/desktop-subscribe.svg";
import { ReactComponent as DesktopRequestSvg } from "../assets/svg/desktop-request.svg";
import { ReactComponent as DesktopReviewSvg } from "../assets/svg/desktop-review.svg";
import { ReactComponent as MobileSubscribeSvg } from "../assets/svg/mobile-subscribe.svg";
import { ReactComponent as MobileRequestSvg } from "../assets/svg/mobile-request.svg";
import { ReactComponent as MobileReviewSvg } from "../assets/svg/mobile-review.svg";

function HowItWorksWrapper() {
  return (
    <>
      <div className='how-it-works-wrapper'>
        <div className='how-it-works-header'>
          <h1>
            How it works?
          </h1>
          <p>
            Follow these 3 simple steps to get started with Subtle Studio
          </p>
        </div>

        <div className='how-it-works-content-list' id='desktop-hiw'>
          <HowItWorksContent 
            imgSvg={<DesktopSubscribeSvg width={"100%"} style={{ maxWidth: "560px", height: "auto" }}/>}
            header={"Subscribe"} 
            caption={"Choose the service that you’d like to subscribe to and select the tier of your favourite. You can opt for monthly or one-time subscription!"}
          />

          <HowItWorksContent 
            imgSvg={<DesktopRequestSvg width={"100%"} style={{ maxWidth: "560px", height: "auto" }}/>}
            header={"Request"} 
            caption={"Once you have made the payment, you can start requesting for your service. You can request 1/2 task at a time, depending on your plan."}
          />

          <HowItWorksContent 
            imgSvg={<DesktopReviewSvg width={"100%"} style={{ maxWidth: "560px", height: "auto" }}/>}
            header={"Review"} 
            caption={"After the design is ready, you can check out your Trello board on the updates. You can start leaving comments until you can finalise the work."}
          />
        </div>

        <div className='how-it-works-content-list' id='mobile-hiw'>
          <HowItWorksContent 
            imgSvg={<MobileSubscribeSvg width={"100%"} style={{ maxWidth: "560px", height: "auto" }}/>}
            header={"Subscribe"} 
            caption={"Choose the service that you’d like to subscribe to and select the tier of your favourite. You can opt for monthly or one-time subscription!"}
          />

          <HowItWorksContent 
            imgSvg={<MobileRequestSvg width={"100%"} style={{ maxWidth: "560px", height: "auto" }}/>}
            header={"Request"} 
            caption={"Once you have made the payment, you can start requesting for your service. You can request 1/2 task at a time, depending on your plan."}
          />

          <HowItWorksContent 
            imgSvg={<MobileReviewSvg width={"100%"} style={{ maxWidth: "560px", height: "auto" }}/>}
            header={"Review"} 
            caption={"After the design is ready, you can check out your Trello board on the updates. You can start leaving comments until you can finalise the work."}
          />
        </div>
      </div>
    </>
  );
}

export default HowItWorksWrapper;
