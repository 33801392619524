import './FooterWrapper.css'
import NavBtn from '../components/NavBtn';
import { ReactComponent as MobileFooterSubtleStudioLogoSvg }  from "../assets/svg/mobile-footer-subtle-studio.svg";
import { ReactComponent as DesktopFooterSubtleStudioLogoSvg }  from "../assets/svg/desktop-footer-subtle-studio.svg";
import { ReactComponent as FacebookLogoSvg }  from "../assets/svg/facebook.svg";
import { ReactComponent as LinkedinLogoSvg }  from "../assets/svg/linked-in.svg";
import { ReactComponent as InstagramLogoSvg }  from "../assets/svg/instagram.svg";

function FooterWrapper() {
    return (
      <>
        <div className='footer-wrapper'>
            <div className='footer-navbtn-wrapper'>
                <NavBtn Name="About Subtle" ID="about-subtle" TextColor = "#0A0B0A" />
                <NavBtn Name="Services" ID="services" TextColor = "#0A0B0A"/>
                <NavBtn Name="Work" ID="work" TextColor = "#0A0B0A"/>
                <NavBtn Name="Pricing" ID="pricing" TextColor = "#0A0B0A" />
                <a href={"https://linktr.ee/sforsubtle"}>
                    <NavBtn Name="Contact Us" ID="null" TextColor = "#0A0B0A"/>
                </a>
                
            </div>

            <DesktopFooterSubtleStudioLogoSvg className='desktop-footer-logo-svg'/>
            <MobileFooterSubtleStudioLogoSvg className='mobile-footer-logo-svg'/>

            <div className='footer-lower-wrapper'>
                <div className='socials-wrapper'>
                    <a href="https://www.linkedin.com/company/sforsubtle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <LinkedinLogoSvg className='socials-svg'/>
                    </a>
                    <a href="https://www.facebook.com">
                        <FacebookLogoSvg className='socials-svg' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                    </a>
                    <a href="https://www.instagram.com">
                        <InstagramLogoSvg className='socials-svg' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                    </a>
                </div>

                <div className='footer-contact-text'>
                    <span>hi@wearesubtle.co</span> | (+60) 11 3047 6757
                </div>
            </div>
        </div>
      </>
    );
  }
  
  export default FooterWrapper;