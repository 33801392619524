import './DesktopNavBar.css';
import NavBtn from "./NavBtn";
import ContactUsBtn from './ContactUsBtn';
import { ReactComponent as DesktopSubtleStudioLogoSvg }  from "../assets/svg/desktop-header-subtle-studio.svg";

function DesktopNavBar() {
    return (
        <>
        <div className="desktop-navbar-wrapper">
                <a href="https://wearesubtle.co" style={{ display: 'inline-block' }}>
                    <div style={{ cursor: 'pointer' }}>
                        <DesktopSubtleStudioLogoSvg />
                    </div>
                </a>

                <div className='navbtn-wrapper'>
                <NavBtn Name="About Subtle" ID="about-subtle" TextColor = "#FFFFFF" />
                <NavBtn Name="Services" ID="services" TextColor = "#FFFFFF"/>
                <NavBtn Name="Work" ID="work" TextColor = "#FFFFFF"/>
                <NavBtn Name="Pricing" ID="pricing" TextColor = "#FFFFFF" />
                <NavBtn Name="FAQ" ID="faq" TextColor = "#FFFFFF"/>
                    <ContactUsBtn href="https://linktr.ee/sforsubtle"/>
                </div>
            </div>
        </>
    );
}

export default DesktopNavBar;


