import './WhySubtleWrapper.css';
import WhySubtleImg from "../assets/svg/why-subtle.svg";

import { ReactComponent as WhySubtleSvg }  from "../assets/svg/why-subtle.svg";

function WhySubtleWrapper() {
  return (
    <>
      <div className='why-subtle-wrapper'>
          {/* <img src={WhySubtleImg} className='why-subtle-img' /> */}
          <WhySubtleSvg width={"100%"} style={{ maxWidth: "560px", height: "auto" }}/>

          <div className='why-subtle-details'>
              <div className='why-subtle-details-header'>
                <h2>
                    Why Subtle?
                </h2>
                <p>
                Still not convinced on engaging with us? Check out our perks:
                </p>
              </div>
              
              <ul>
                  <li>Kickstart your journey with a complimentary 1-hour consultation.</li>
                  <li>Our services are crafted to give you the best bang for your buck.</li>
                  <li>Everything is done in an async way, no more boring meetings!</li>
                  <li>From pixel-perfect designs to clean code, you’re in good hands.</li>
                  <li>Track your requests and collaborate with our team on Trello.</li>
              </ul>
          </div>
      </div>
    </>
  );
}

export default WhySubtleWrapper;
