import './FaqWrapper.css';

import FaqContent from '../components/FaqContent';

// Questions and Answers
const faqs = [
    {
      question: "Why should I hire Subtle Studio and not a full-time designer?",
      answer: "Hiring a full-time designer might be a big commitment! That’s why with Subtle Studio, you can just simply request design from us without any commitment!"
    },
    {
      question: "How many rounds of revisions do I get per request?",
      answer: "It depends on the plan you choose! The basic plan offers 3 rounds of revisions."
    },
    {
      question: "What are rounds of revisions?",
      answer: "Rounds of revisions refer to the number of times you can request changes to a design. In the 1st round, you can compile all your comments, and the designer will make the necessary changes. After that, you have 2 more rounds to provide additional feedback."
    },
    {
      question: "What if I exceed the rounds of revisions?",
      answer: "If you exceed the allotted rounds of revisions, you will need to pay an additional fee for each extra round. The cost for one additional round of revision ranges from RM20 to RM50."
    },
    {
      question: "What programs do you design in?",
      answer: "We use Figma for UI/UX design, Adobe Photoshop and Canva for digital design, and Adobe Illustrator for print design."
    },
    {
      question: "What if I don’t like the design?",
      answer: "With our plans, you can choose an option that offers more than one initial concept. If you don’t like any of the initial concepts, you can request additional designs for an extra charge."
    },
    {
      question: "Is there any design work you don’t cover?",
      answer: "Yes, we do not offer 3D modeling, animated graphics, complex packaging, or extensive print design."
    }
];

function FaqWrapper() {
  return (
    <>
      <div className='faq-tab-wrapper' id = "faq">
        <header>Commonly asked questions</header>

        <section>
          {faqs.map((faq, index) => (
            <FaqContent 
              key={index} 
              Question={faq.question} 
              Answer={faq.answer} 
            />
          ))}
        </section>

        <a href={"https://wearesubtle.notion.site/Frequently-Asked-Questions-2171cc76d9674c0cbc0dedad10a2751d"} className='visit-faq-btn'>
            {"Visit our FAQ centre"}
        </a>

      </div>
    </>
  );
}

export default FaqWrapper;


