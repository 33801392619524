import React from 'react';
import './ContactUsBtn.css';

function ContactUsBtn({ href }) {
    return (
        <a href={href} className='contact-us-btn'>
            {"Contact Us"}
        </a>
    );
}

export default ContactUsBtn;


