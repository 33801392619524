import './PricesContent.css';
import { ReactComponent as StandardPriceLogoSvg }  from "../assets/svg/standard-price.svg";
import { ReactComponent as ElitePriceLogoSvg }  from "../assets/svg/elite-price.svg";

function PricesContent({ Mode, Tier }) {
  const standardDesignPrice = 250;
  const eliteDesignPrice = 500;

  const standardDesignDetailsList = [
    "Price charged per design/page/task",
    "3 rounds of revisions",
    "4-5 days delivery",
    "1 initial concept",
  ];

  const standardDesignLimitationsList = [
    "Source file included"
  ];

  const eliteDesignDetailsList = [
    "Price charged per design/page/task",
    "3 rounds of revisions",
    "4-5 days delivery",
    "1 initial concept",
    "Source file included",
  ];

  const standardWebDevPrice = 1500;
  const eliteWebDevPrice = 3000;

  const standardWebDevDetailsList = [
    "Including website design",
    "3 rounds of revisions",
    "Including development (Jr Developer)",
    "Suitable for a landing page",
  ];

  const standardWebDevLimitationsList = [
    "Mobile responsive screens included",
  ];

  const eliteWebDevDetailsList = [
    "Including website design",
    "3 rounds of revisions",
    "Including development (Jr Developer)",
    "Suitable for a landing page",
    "Mobile responsive screens included",
  ];

  // Determine which lists to use based on Mode and Tier
  const detailsList = Mode === "WebDev" 
    ? (Tier === "Elite" ? eliteWebDevDetailsList : standardWebDevDetailsList)
    : (Tier === "Elite" ? eliteDesignDetailsList : standardDesignDetailsList);

  const limitationsList = Mode === "WebDev" 
    ? (Tier === "Elite" ? [] : standardWebDevLimitationsList)
    : (Tier === "Elite" ? [] : standardDesignLimitationsList);

    const displayPrice = Mode === "WebDev" 
    ? (Tier === "Elite" ? eliteWebDevPrice : standardWebDevPrice)
    : (Tier === "Elite" ? eliteDesignPrice : standardDesignPrice);

  return (
    <div className='prices-content-wrapper' id="pricing">
      {Tier === "Elite" ? <ElitePriceLogoSvg /> : <StandardPriceLogoSvg />}
      
      <div className='price-details-outer-wrapper'>
            <header>{Tier}</header>
            
            <p className='display-price-text'> from <span>RM{displayPrice}</span></p>
            <div className='details-limitations-list-wrapper'>
                <ul className="details-list">
                    {detailsList.map((detail, index) => (
                    <li key={index}>{detail}</li>
                    ))}
                </ul>

                <ul className="limitations-list">
                    {limitationsList.map((limitation, index) => (
                    <li key={index}>{limitation}</li>
                    ))}
                </ul>
            </div>
        </div>
      
      <a href={"https://linktr.ee/sforsubtle"} className='get-started-now-btn'>
            {"Get started now"}
        </a>
    </div>
  );
}

export default PricesContent;
